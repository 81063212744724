<template>
  <div>
    <v-container id="regular-tables" fluid tag="section" class="px-15">
      <v-row>
        <v-col md="12" class="pb-7">
          <v-btn
            :loading="submitLoading"
            @click="submitAbsentEdit"
            class="white--text"
            color="green"
            depressed
            >{{ $t('edit') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ name }}
            </th>
          </tr>
        </thead>
        <v-fade-transition mode="out-in" :group="true" tag="tbody">
          <tr
            v-for="(absent, idx) in absentEditData.absents"
            :key="absent.registration_record_id"
          >
            <td>{{ idx + 1 }}</td>
            <td>{{ absent.name }}</td>
            <td>
              <v-checkbox
                v-model="absent.absent"
                @change="emptyFields(absent)"
              ></v-checkbox>
            </td>
            <td class="pt-1">
              <v-autocomplete
              
                
                dense
                flat
                :disabled="!absent.absent"
                :items="absentTypes"
                item-text="name"
                item-value="value"
                v-model="absent.type"
              ></v-autocomplete>
            </td>
            <td class="pt-1">
              <v-text-field
                :disabled="!absent.absent"
                v-model="absent.note"
                full-width
                dense
                
                flat
                color="#757575"
              ></v-text-field>
            </td>
          </tr>
        </v-fade-transition>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      submitLoading: false,
      eduClassId: this.$route.params.eduClassId,
      validForm: false,
      tableHeaders: ["Id", "اسم الطالب", "غياب", "السبب", "الملاحظة"],
      absentTypes: [
        {
          name: "غياب مبرر",
          value: "justified",
        },
        {
          name: "غياب غير مبرر",
          value: "unjustified",
        },
      ],
      originalAbsentEditData: [],
    };
  },
  props: {
    absentEditData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    emptyFields(item) {
      if (!item.absent) {
        item.note = "";
        item.type = "";
      } else {
        item.type = "justified";
      }
    },
    async submitAbsentEdit() {
      try {
        this.submitLoading = true;
        let toBeSubmittedAbsents = [];
        let manipulatedAbsentData = this.absentEditData.absents;
        for (let idx in manipulatedAbsentData) {
          if (
            manipulatedAbsentData[idx].absent &&
            !this.originalAbsentEditData[idx].absent
          ) {
            toBeSubmittedAbsents.push({
              registration_record_id:
                manipulatedAbsentData[idx].registration_record_id,
              type: manipulatedAbsentData[idx].type,
              absent: manipulatedAbsentData[idx].absent,
              note: manipulatedAbsentData[idx].note,
            });
          }
          if (
            !manipulatedAbsentData[idx].absent &&
            this.originalAbsentEditData[idx].absent
          ) {
            toBeSubmittedAbsents.push({
              registration_record_id:
                manipulatedAbsentData[idx].registration_record_id,
              type: manipulatedAbsentData[idx].type,
              absent: manipulatedAbsentData[idx].absent,
              note: manipulatedAbsentData[idx].note,
            });
          }
          if (
            manipulatedAbsentData[idx].absent &&
            this.originalAbsentEditData[idx].absent
          ) {
            if (
              manipulatedAbsentData[idx].note !=
                this.originalAbsentEditData[idx].note ||
              manipulatedAbsentData[idx].type !=
                this.originalAbsentEditData[idx].type
            ) {
              toBeSubmittedAbsents.push({
                registration_record_id:
                  manipulatedAbsentData[idx].registration_record_id,
                type: manipulatedAbsentData[idx].type,
                absent: manipulatedAbsentData[idx].absent,
                note: manipulatedAbsentData[idx].note,
              });
            }
          }
        }
        await axios.post("/absent", {
          date: this.absentEditData.date,
          absents: toBeSubmittedAbsents,
          edu_class_id: this.eduClassId,
        });
        this.$Notifications(
          this.$t('add success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("absentsEdited");
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
  created() {
    this.absentEditData.absents.forEach((el) => {
      this.originalAbsentEditData.push({ ...el });
    });
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="table__spinnerWrapper" v-if="loading">
      <app-spinner></app-spinner>
    </div>
    <div v-else>
      <v-row class="py-10 px-10 d-flex justify-space-between align-center">
        <div>
          <h4> {{ $t('education class name') }}</h4>
          <h2>{{ getEduClassInfo.name }}</h2>
        </div>
        <div>
          <h4>{{ $t('class') }}</h4>
          <h2>{{ getEduClassInfo.grade.name }}</h2>
        </div>
        <div>
          <h4>{{ $t('room') }}</h4>
          <h2>{{ getEduClassInfo.room.floor.name }}</h2>
        </div>
        <div>
          <h4>{{ $t('total students') }}</h4>
          <h2>{{ getEduClassInfo.registration_record_count }}</h2>
        </div>
      </v-row>
      <v-row></v-row>
      <v-card elevation="0">
        <v-toolbar>
          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title color="#757575">
              <v-tabs-slider></v-tabs-slider>
              <v-tab :disabled="disableTabs" :href="'#' + '1'"
                v-if="returnAbility('registration-record:index')">{{ $t('students') }}</v-tab>
              <v-tab :disabled="disableTabs" v-if="returnAbility('note:index')" :href="'#' + '2'">{{ $t('notes') }}</v-tab>
              <v-tab :disabled="disableTabs" @click="showEduClassTest = false" v-if="returnAbility('test:index')"
                :href="'#' + '3'">{{ $t('Test') }}</v-tab>
              <v-tab :disabled="disableTabs" @click="showAbsentEdit = false" v-if="returnAbility('absent:index')"
                :href="'#' + '4'">{{ $t('absence') }}</v-tab>
            </v-tabs>
            <v-btn rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text"
              @click="$router.push(`/newYear/${eduClassId}`)">
              <v-icon>mdi-plus</v-icon>
              {{ $t('add student to new year') }}
            </v-btn>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item value="1">
            <app-students :id="eduClassId" v-if="tab == '1' && returnAbility('registration-record:index')"
              idType="edu-class"></app-students>
          </v-tab-item>
          <v-tab-item value="2">
            <app-notes v-if="tab == '2' && returnAbility('note:index')" :id="eduClassId" idType="edu-class"></app-notes>
          </v-tab-item>
          <v-tab-item value="3">
            <app-single-edu-class-test v-if="tab == '3' && returnAbility('mark:index') && showEduClassTest"
            :eduClassTest="singleEduClassTestData" @testsStored="showEduClassTest = false"></app-single-edu-class-test>
            <app-tests-view @displaySingleTest="displaySingleTest($event)" v-if="tab == '3' && returnAbility('test:index') && !showEduClassTest"
            :id="eduClassId" idType="edu-class"></app-tests-view>
          </v-tab-item>
          <v-tab-item value="4">
            <app-absent-index v-if="tab == '4' && returnAbility('absent:index') && !showAbsentEdit" 
            @goToAbsentEdit="displayAbsentEdit($event)"></app-absent-index>
            <app-absent-edit v-if="tab == '4' && returnAbility('absent:store') && showAbsentEdit"
            @absentsEdited="showAbsentEdit = false" :absentEditData="absentEditData"></app-absent-edit>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import students from "../../shared-eduClass&grade/students.vue";
import notes from "../../shared-eduClass&grade/notes/notes.vue";
import testsView from "../../shared-eduClass&grade/tests/testsView.vue";
import singleEduClassTest from "./singleEduClassTest.vue";
import absentIndex from "./absent/absentIndex.vue";
import absentEdit from "./absent/absentEdit.vue";
export default {
  components: {
    appSingleEduClassTest: singleEduClassTest,
    appTestsView: testsView,
    appNotes: notes,
    appStudents: students,
    appAbsentIndex: absentIndex,
    appAbsentEdit: absentEdit,
  },
  data() {
    return {
      tab: false,
      eduClassId: this.$route.params.eduClassId,
      loading: false,
      showEduClassTest: false,
      showAbsentEdit: false,
      singleEduClassTestData: {},
      absentEditData: [],
    };
  },

  computed: {
    ...mapGetters({
      getEduClassInfo: "educationClass/getEduClassInfo",
      getTableLoadingStatus: "tablesStore/getTableLoading",
    }),
    disableTabs() {
      if (this.getTableLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchEduClassInfo: "educationClass/fetchEduClassInfo",
    }),
    displaySingleTest(eduClassTest) {
      this.singleEduClassTestData = eduClassTest;
      this.showEduClassTest = true;
    },
    displayAbsentEdit(data) {
      this.showAbsentEdit = true;
      this.absentEditData = data;
    },
  },
  created() {
    this.loading = true;
    this.fetchEduClassInfo(this.eduClassId).finally((_) => {
      this.loading = false;
    });
  },
};
</script>

<style>

</style>
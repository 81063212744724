<template>
  <div>
    <div class="table__spinnerWrapper" v-if="itemsLoading">
      <app-spinner></app-spinner>
    </div>
    <div v-else>
      <v-row class="py-10 px-10 d-flex justify-space-between align-center">
        <div>
          <h4>اسم الاختبار</h4>
          <h2>{{ eduClassTest.name }}</h2>
        </div>
        <div>
          <h4>المادة</h4>
          <h2>{{ eduClassTest.subject.name }}</h2>
        </div>
        <div>
          <h4>الشعبة</h4>
          <h2>{{ eduClassTest.edu_class.name }}</h2>
        </div>
        <div>
          <h4>التاريخ</h4>
          <h2>{{ eduClassTest.date }}</h2>
        </div>
        <div>
          <h4>العلامة العظمى</h4>
          <h2>{{ eduClassTest.max_mark }}</h2>
        </div>
        <div>
          <h4>القسم</h4>
          <h2>{{ eduClassTest.subject_partition.name }}</h2>
        </div>
        <div>
          <v-btn
            v-if="returnAbility('mark:store')"
            color="green"
            class="white--text"
            depressed
            rounded
            @click="submitTests"
            :loading="submitBtnLoading"
          >
            <v-icon>mdi-plus</v-icon>
            تخزين</v-btn
          >
        </div>
      </v-row>
      <v-container id="regular-tables" fluid tag="section" class="px-15">
        <v-form v-model="validForm" ref="formValidation">
          <v-simple-table :dark="$store.state.isDarkMode">
            <thead class="table-heading">
              <tr>
                <th v-for="(name, i) in tableHeaders" :key="i">
                  {{ name }}
                </th>
              </tr>
            </thead>
            <v-fade-transition mode="out-in" :group="true" tag="tbody">
              <tr
                v-for="(student, idx) in students"
                :key="student.registration_record_id"
                :class="{ hilightRow: !student.mark }"
              >
                <td>{{ idx + 1 }}</td>
                <td>{{ student.name }}</td>
                <td class="pt-1">
                  <v-text-field
                    :disabled="!returnAbility('mark:store')"
                    :rules="[rules.min, rules.max]"
                    full-width
                    dense
                    
                    type="number"
                    flat
                    v-model="student.mark"
                    color="#757575"
                  ></v-text-field>
                </td>
              </tr>
            </v-fade-transition>
          </v-simple-table>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      validForm: false,
      tableHeaders: ["Id", "اسم الطالب", "العلامة"],
      students: [],
      originalStudents: [],
      submitBtnLoading: false,
      itemsLoading: false,
      rules: {
        min: (v) =>
          Number(v) >= Number(0) ||
          !v ||
          `العلامة الدنيا 0`,
        max: (v) =>
          Number(v) <= Number(this.eduClassTest.max_mark) ||
          `العلامة العظمى ${this.eduClassTest.max_mark}`,
      },
    };
  },
  props: {
    eduClassTest: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    async submitTests() {
      try {
        if (!this.validForm) {
          this.$Notifications(
            "يوجد خطأ في إدخال إحدى العلامات",
            { rtl: true, timeout: 4000 },
            "error"
          );
          return;
        }
        this.submitBtnLoading = true;
        let ToBeSubmittedMarks = [];
        for (let idx in this.originalStudents) {
          if (this.originalStudents[idx].mark && !this.students[idx].mark) {
            this.students[idx].mark = null;
            ToBeSubmittedMarks.push(this.students[idx]);
            continue;
          }
          if (this.originalStudents[idx].mark != this.students[idx].mark) {
            this.students[idx].mark = this.students[idx].mark;
            ToBeSubmittedMarks.push(this.students[idx]);
          }
        }
        await axios.post("/mark", {
          test_id: this.eduClassTest.id,
          marks: ToBeSubmittedMarks,
        });
        this.$Notifications(
          "تم التخزين بنجاح",
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("testsStored");
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  async created() {
    try {
      this.itemsLoading = true;
      const studentsTests = await axios.get("/mark", {
        params: {
          test_id: this.eduClassTest.id,
        },
      });
      this.students = studentsTests.data.marks;
      studentsTests.data.marks.forEach((el) => {
        this.originalStudents.push({ ...el });
      });
      this.$refs.formValidation.validate();
    } catch (err) {
      console.log("err", err);
    } finally {
      this.itemsLoading = false;
    }
  },
};
</script>

<style>
.hilightRow {
  background: rgb(250, 234, 234);
}
</style>